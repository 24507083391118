<template>
    <v-container fluid style="background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="#84109a">
                        <v-spacer />
                        <b>Digite a nova senha (mínimo 8 caracteres, use letras e números)</b>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <div>{{ getEmail }}</div>
                        <v-form>
                            <fieldset style="padding: 5px; border-radius: 5px;">
                                <legend> Informe o código recebido no seu email </legend>
                                <v-otp-input
                                    v-model="token"
                                    length="6"
                                />
                            </fieldset>
                            <v-text-field
                                label="Nova senha"
                                ref="password"
                                v-model="password"
                                prepend-icon="lock"
                                autocomplete="current-password"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            />
                            <v-text-field
                                label="Confirmar nova senha"
                                ref="password"
                                v-model="passwordConfirm"
                                prepend-icon="lock"
                                @keypress.enter="confirmar"
                                autocomplete="current-password"
                                :type="showPasswordConfirm ? 'text' : 'password'"
                                @click:append="showPasswordConfirm = !showPasswordConfirm"
                                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            block
                            dark
                            large
                            @click="confirmar"
                            color="#84109a"
                            :loading="loading"
                            class="white--text"
                        >Confirmar</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn
                            block
                            text
                            small
                            @click="goToLogin"
                            color="#84109a"
                            class="white--text"
                        >Voltar ao login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'NewPassword',

    data: () => ({
        loading: false,
        token: '',
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showPasswordConfirm: false,
    }),

    computed: {
        getEmail() {
            return decodeURIComponent(this.$route.query.email);
        },
    },

    methods: {
        confirmar() {
            if (this.token.length < 6) {
                this.$toast.error('Código inválido');
                return;
            }

            if (this.password.length < 8) {
                this.$toast.error('Senha deve ter ao menos 8 digitos');
                return;
            }

            if (this.password != this.passwordConfirm) {
                this.$toast.error('A senha não confere');
                return;
            }

            this.loading = true;
            this.$http.post('reset-password', {
                token: this.token,
                email: this.getEmail,
                password: this.password,
                passwordConfirm: this.passwordConfirm,
            }).then(async (resp) => {
                const data = resp.data;

                if (data.type == 'warning') {
                    this.$toast.error(data.msg);
                    return;
                }

                this.$toast.success(data.msg);

                this.$router.push('login');
            })
            .catch(() => (this.$toast.error('Não foi possivel realizar login', 'warning')))
            .finally(() => (this.loading = false))
        },

        goToLogin() {
            sessionStorage.clear();
            this.$store.commit('setUser', {});
            this.$router.push('/login');
        },
    }
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: auto;
    justify-content: center;
}
</style>
